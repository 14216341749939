import styled from "styled-components"
import { device } from "breakpoints"
import { Carousel } from "../Carousel"
import { MainColumn } from "components/MainColumn"

import {
  SlidesContainer,
  ButtonsContainer,
  DotsContainer,
  DotButton,
} from "../Carousel/style"

export const Container = styled(MainColumn)`
  padding-top: 40px;
  padding-top: ${props => !props.content && "0"};
  border-top: 1px solid #dce0e7;
  border-top: ${props => !props.content && "none"};

  margin-bottom: 80px;
  @media ${device.desktop} {
    padding-top: 90px;
    padding-top: ${props => !props.content && "0px"};
  }
`
export const MainTitle = styled.h2`
  font-size: 28px;
  color: #2a3449;
  letter-spacing: -0.44px;
  line-height: 28px;
  margin-bottom: 30px;
  text-align: center;
  @media ${device.desktop} {
    text-align: left;
    font-size: 36px;
    letter-spacing: -0.56px;
    line-height: 28px;
    margin-bottom: 50px;
  }
`

export const NewCarousel = styled(Carousel)`
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: visible;

  @media ${device.desktop} {
    box-shadow: none;
    margin: 0 -20px;
    .embla-carousel {
      overflow: visible !important;
      padding-right: 0;
    }
  }
  .embla-carousel {
    @media ${device.desktop} {
      padding-bottom: 50px;
    }
  }

  ${SlidesContainer} {
    @media ${device.desktop} {
      padding: 0;
      .is-selected {
        left: 0 !important;
      }
    }
  }
  ${ButtonsContainer} {
    display: none;
    margin-top: 68px !important;
  }
  ${DotsContainer} {
    position: absolute;
    bottom: -40px;
    margin-top: 0;
    margin-left: 0;
    height: 6px;

    @media ${device.desktop} {
      display: none;
    }
  }
  ${DotButton} {
    width: 6px;
    height: 6px;
  }
`
