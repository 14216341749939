import styled from "styled-components"
import { device } from "breakpoints"
import { Teaser } from "components/Teaser"
import {
  ImageContainer,
  Title,
  Excerpt,
  InfoContainer,
} from "components/Teaser/style"

export const RelatedTeaserContainer = styled(Teaser)`
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  min-height: 100%;

  @media ${device.desktop} {
    min-height: 100%;
  }
  ${ImageContainer} {
    padding-top: 55.55555556%;
  }

  ${Title} {
    font-size: 20px;
    margin-bottom: 14px;

    @media ${device.desktop} {
      font-size: 24px;
    }
  }

  ${Excerpt} {
    font-size: 16px;

    @media ${device.large} {
      font-size: 18px;
    }
  }

  ${InfoContainer} {
    text-align: center;
    padding: 30px;
    padding-top: 14px;
    @media ${device.desktop} {
      text-align: left;
    }

    @media ${device.large} {
      padding: 40px;
      padding-top: 25px;
    }
  }
`
