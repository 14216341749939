import React from "react"
import useMedia from "hooks/useMedia.js"
import { Container, MainTitle, NewCarousel } from "./style"
import { RelatedTeaser } from "components/Teaser/RelatedTeaser"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import { NewTeaser } from "components/TeaserNews/style"

const RelatedTeasers = ({ data, content, article }) => {
  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )

  const displayGrid = data?.displayLanding

  return (
    <Container content={content}>
      {article ? (
        <MainTitle>Related articles</MainTitle>
      ) : data?.teaserblockheader ? (
        <MainTitle>{data?.teaserblockheader}</MainTitle>
      ) : null}

      {!displayGrid ? (
        <NewCarousel
          loop={!isDesktop}
          slidesToShow={[1, 3]}
          draggable={!isDesktop}
          gap={40}
        >
          {data?.teaserblockitems?.slice(0, 3).map((teaser, index) => {
            if (article) {
              return (
                <NewTeaser
                  key={index}
                  title={teaser?.title}
                  excerpt={teaser?.excerpt}
                  tag={teaser?.categories?.nodes[0]?.name}
                  img={{
                    ...teaser?.featuredImage,
                    sizes: "(min-width: 1024px) 370px, 100vw",
                  }}
                  link={teaser?.link}
                  centered
                />
              )
            } else {
              return (
                <RelatedTeaser
                  key={index}
                  title={teaser?.title}
                  excerpt={teaser?.excerpt}
                  img={{
                    ...teaser?.featuredImage,
                    sizes: "(min-width: 1024px) 370px, 100vw",
                  }}
                  link={teaser?.link}
                />
              )
            }
          })}
        </NewCarousel>
      ) : (
        <FlexGrid fullWidth gap={[30, 20, 30, 40]}>
          <FlexRow>
            {data?.teaserblockitems?.map((teaser, index) => {
              if (article) {
                return (
                  <FlexCol key={index} xs={12} sm={6} md={4}>
                    <NewTeaser
                      key={index}
                      title={teaser?.title}
                      excerpt={teaser?.excerpt}
                      tag={teaser?.categories?.nodes[0]?.name}
                      img={{
                        ...teaser?.featuredImage,
                        sizes: "(min-width: 1024px) 370px, 100vw",
                      }}
                      link={teaser?.link}
                      centered
                    />
                  </FlexCol>
                )
              } else {
                return (
                  <FlexCol key={index} xs={12} sm={6} md={4}>
                    <RelatedTeaser
                      title={teaser?.title}
                      excerpt={teaser?.excerpt}
                      img={{
                        ...teaser?.featuredImage,
                        sizes:
                          "(min-width: 768px) 50vw, (min-width: 1024px) 370px, 100vw",
                      }}
                      link={teaser?.link}
                    />
                  </FlexCol>
                )
              }
            })}
          </FlexRow>
        </FlexGrid>
      )}
    </Container>
  )
}

export default RelatedTeasers
