import styled from "styled-components"
import { device } from "breakpoints"
import { FlexCol } from "../FlexGrid"
import { FlexGrid } from "../FlexGrid"

export const StatsGrid = styled(FlexGrid)`
  margin-top: ${props => props.landing && "-25px"};

  @media ${device.tablet} {
    margin-top: 0;
  }
`
export const Container = styled.div`
  width: 100%;
`
export const Title = styled.h3`
  font-size: 28px;
  color: #2a3449;
  letter-spacing: -0.44px;
  text-align: center;
  line-height: 34px;
  margin-bottom: 30px;

  @media (min-width: 500px) {
    font-size: 36px;
    color: #2a3449;
    letter-spacing: -0.56px;
    text-align: center;
    line-height: 28px;

    margin-bottom: 46px;
  }
`
export const Number = styled.h4`
  font-weight: 300;
  font-size: 42px;
  color: #f17071;
  letter-spacing: -1.05px;
  text-align: center;
  line-height: 42px;
  margin: 7px 0;

  @media (min-width: 500px) {
    font-size: 60px;
    color: #f17071;
    letter-spacing: -1.5px;
    text-align: center;
    line-height: 64px;
    margin-top: 16px;
    margin-bottom: 13px;
  }
`
export const Text = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #2a3449;
  letter-spacing: -0.28px;
  text-align: center;
  line-height: 28px;

  @media (min-width: 500px) {
    font-size: 24px;
    color: #2a3449;
    letter-spacing: -0.38px;
    text-align: center;
    line-height: 28px;
  }
`
export const IconContainer = styled.div`
  margin: 0 auto;

  svg {
    height: 38px !important;
    width: 38px !important;
  }

  @media (min-width: 500px) {
    svg {
      transform: scale(1.2) !important;
    }
  }
`
export const InnerContainer = styled.div`
  text-align: center;
  padding: 0 15px;
  padding-bottom: 35px;
  margin-bottom: 15px;
  position: relative;

  ::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #dce0e7;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  :last-child {
    ::after {
      display: none;
    }
  }

  /* @media ${device.tablet} {
    ::after {
      content: "";
      height: 146px;
      width: 1px;
      background-color: #dce0e7;
      position: absolute;
      top: 50px;
      left: 100%;
      right: 0;
    }

    :last-child {
      ::after {
        display: none;
      }
    }
  } */
`
export const NewFlexCol = styled(FlexCol)`
  justify-content: center;
  position: relative;
  margin-top: 13px;

  :nth-child(2) {
    margin-top: 0;
  }

  @media ${device.tablet} {
    margin-top: 0;

    ::after {
      content: "";
      height: 146px;
      width: 1px;
      background-color: #dce0e7;
      position: absolute;
      top: 50px;
      left: 100%;

      display: ${props => (props.last || props.header) && "none"};
    }
  }
`
