import React from "react"

import { FlexRow } from "../FlexGrid"
import { Icon } from "helpers/getIcon"
import {
  StatsGrid,
  IconContainer,
  InnerContainer,
  Title,
  Number,
  Text,
  NewFlexCol,
} from "./style"

const Stats = ({ data, landing }) => {
  return (
    <StatsGrid gap={[0]} landing={landing}>
      <FlexRow center="xs">
        {data.sHeader && (
          <NewFlexCol header xs={12}>
            <Title>{data.sHeader}</Title>
          </NewFlexCol>
        )}
        {data?.sItems?.slice(0, 3).map((item, index) => {
          return (
            <NewFlexCol
              last={index === data.sItems.length - 1}
              key={index}
              xs={12}
              sm={4}
            >
              <InnerContainer>
                <IconContainer>
                  <Icon name={item.icon} />
                </IconContainer>
                <Number>{item.primaryText}</Number>
                <Text>{item.secondaryText}</Text>
              </InnerContainer>
            </NewFlexCol>
          )
        })}
      </FlexRow>
    </StatsGrid>
  )
}

export default Stats
